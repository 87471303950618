<template>
  <div>
    <div v-if="offerList.length" class="list-view product-checkout mt-0">
      <OfferProducts />

      <!-- Checkout Options -->
      <div>
        <div class="checkout-options">
          <b-card>
            <div class="d-flex justify-content-between align-items-center ">
              <label class="section-label mb-1">
                {{ $t('offer.list') }}
              </label>
              <label v-if="offerList.length" @click="clearOfferList()" class="section-label mb-1 text-danger small cursor-pointer">
                {{ $t('offer.clear_swal.title') }}
              </label>
            </div>
            <hr />
            <div>
              <validation-observer ref="offerValidation" v-slot="{ invalid, errors, validate }">
                <b-row>
                  <!-- #region Ad -->
                  <b-col cols="12">
                    <b-form-group :label="$t('offer.detail.name')" label-for="offer-detail-name">
                      <validation-provider #default="{ errors }" :name="$t('offer.detail.name')" rules="required|min:3">
                        <b-form-input id="offer-detail-name" v-model="offererDetail.name" :state="errors.length > 0 ? false : null" :placeholder="$t('offer.detail.name_placeholder')" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- #endregion -->

                  <!-- #region Soyad -->
                  <b-col cols="12">
                    <b-form-group :label="$t('offer.detail.surname')" label-for="offer-detail-surname">
                      <validation-provider #default="{ errors }" :name="$t('offer.detail.surname')" rules="required|min:3">
                        <b-form-input id="offer-detail-surname" v-model="offererDetail.surname" :state="errors.length > 0 ? false : null" :placeholder="$t('offer.detail.surname_placeholder')" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- #endregion -->

                  <!-- #region Telefon -->
                  <b-col cols="12">
                    <b-form-group :label="$t('offer.detail.phone')" label-for="offer-detail-phone">
                      <validation-provider #default="{ errors }" :name="$t('offer.detail.phone')" rules="required|min:10|max:10">
                        <cleave
                          id="offer-detail-phone"
                          v-model="offererDetail.phone"
                          :options="phoneOptions"
                          class="form-control"
                          :class="{
                            'is-invalid': errors.length > 0
                          }"
                          :placeholder="$t('offer.detail.phone_placeholder')"
                        />
                        <b-form-invalid-feedback>
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- #endregion -->

                  <!-- #region Firma -->
                  <b-col cols="12">
                    <b-form-group :label="$t('offer.detail.company')" label-for="offer-detail-company">
                      <validation-provider #default="{ errors }" :name="$t('offer.detail.company')" rules="min:5">
                        <b-form-input id="offer-detail-company" v-model="offererDetail.company" :state="errors.length > 0 ? false : null" :placeholder="$t('offer.detail.company_placeholder')" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- #endregion -->

                  <!-- #region Email -->
                  <b-col cols="12">
                    <b-form-group :label="$t('offer.detail.email')" label-for="offer-detail-email">
                      <validation-provider #default="{ errors }" :name="$t('offer.detail.email')" rules="email">
                        <b-form-input id="offer-detail-email" v-model="offererDetail.email" :state="errors.length > 0 ? false : null" :placeholder="$t('offer.detail.email_placeholder')" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- #endregion -->

                  <!-- #region Açıklama -->
                  <b-col cols="12">
                    <b-form-group :label="$t('offer.detail.detail')" label-for="offer-detail-detail">
                      <b-form-textarea id="offer-detail-detail" v-model="offererDetail.detail" :state="errors.length > 0 ? false : null" :placeholder="$t('offer.detail.detail_placeholder')" rows="3" max-rows="5" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </b-col>
                  <!-- #endregion -->

                  <!-- #region İşlemler -->
                  <b-col cols="12">
                    <div class="d-flex justify-content-end align-items-center">
                      <b-button :variant="invalid ? 'danger' : 'success'" @click="offerRequest()">
                        {{ $t('offer.go_to_offer_page') }}
                      </b-button>
                    </div>
                  </b-col>
                  <!-- #endregion -->
                </b-row>
              </validation-observer>
            </div>
          </b-card>
        </div>
      </div>
    </div>
    <div v-else class="d-flex flex-column justify-content-center align-items-center">
      <feather-icon icon="BriefcaseIcon" height="100" width="100" />
      <h1 class="my-2">{{ $t('offer.empty') }}</h1>
      <div>
        <b-button variant="primary" class="btn-md mr-1" :to="{ path: '/' }">
          {{ $t('BackToHome') }}
        </b-button>
        <b-button variant="success" class="btn-md" :to="{ name: 'Products' }">
          {{ $t('offer.go_to_products_page') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BFormInvalidFeedback, BButton } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import OfferProducts from './OfferProducts.vue'
import { CLEAR_OFFER, OFFER_REQUEST } from '@/store/services/offer-service'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.tr.js'
import { SET_NOTIFICATION } from '@/store/services/notification-service'

export default {
  components: { BCard, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BFormInvalidFeedback, BButton, OfferProducts, ValidationObserver, ValidationProvider, Cleave },
  data() {
    return {
      phoneOptions: {
        phone: true,
        phoneRegionCode: 'TR'
      }
    }
  },
  computed: {
    ...mapGetters(['offerList', 'offererDetail'])
  },
  methods: {
    clearOfferList() {
      this.$store.commit(CLEAR_OFFER)
      this.$store.commit(SET_NOTIFICATION, {
        status: true,
        title: this.$t('offer.list'),
        message: this.$t('offer.clear_offer_message')
      })
    },
    offerRequest() {
      this.$refs.offerValidation.validate().then(res => {
        if (res) {
          this.$store.dispatch(OFFER_REQUEST).then(res => {
            if (res.status) {
              this.$router.push({ name: 'OfferThanks', params: { id: res.offer_no } })
            }
          })
        } else {
          this.$store.commit(SET_NOTIFICATION, {
            status: false,
            title: this.$t('offer.list'),
            message: this.$t('offer.offer_form_message')
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
</style>

<template>
  <div class="checkout-items">
    <b-card v-for="(product, index) in offerList" :key="`offer_product_${product.product_id}_${index}`" class="ecommerce-card" no-body>
      <!-- Product Image -->
      <div class="item-img">
        <b-link
          :to="{
            name: 'ProductDetail',
            params: { id: product.product_id }
          }"
          target="_blank"
        >
          <b-img :src="productImage(product)" :alt="`${product.product_name}`" />
        </b-link>
        <div v-if="product.brand_image_id" class="brand-image">
          <img :src="`https://${host}/Image/GetImage?image_id=${product.brand_image_id}`" :alt="`${product.brand_name} Photo`" />
        </div>
      </div>

      <!-- Product Details: Card Body -->
      <b-card-body>
        <span>{{ product.brand_name }}</span>
        <div class="item-name">
          <h6 class="mb-1">
            <b-link
              class="text-body"
              :to="{
                name: 'ProductDetail',
                params: { id: product.product_id }
              }"
              target="_blank"
            >
              {{ product.product_name }}
            </b-link>
          </h6>
        </div>

        <div class="item-quantity-area my-1">
          <p v-if="product.unit_id !== product.sales_unit_id" class="mb-0 pr-875 text-center" style="width: 20rem;">{{ getStockPieceByUnitID(product) }}</p>
          <change-quantity :productId="product.product_id" :unitId="product.unit_id" style="width: 20rem;" />
        </div>
      </b-card-body>

      <!-- Product Options/Actions -->
      <div class="item-options text-center">
        <b-button variant="light" class="mt-1 remove-wishlist" @click="removeProductFromOfferClick(product)">
          <feather-icon icon="TrashIcon" class="mr-50" />
          <span>{{ $t('shop.remove') }}</span>
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardHeader, BCardText, BCardTitle, BCardBody, BLink, BImg, BButton, BBadge, VBTooltip, BFormGroup } from 'bootstrap-vue'
import ChangeQuantity from '@/views/Order/Shop/ChangeQuantity.vue'
import { mapGetters } from 'vuex'
import { REMOVE_OFFER } from '@/store/services/offer-service'
import { SET_NOTIFICATION } from '@/store/services/notification-service'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardText,
    BCardBody,
    BCardTitle,
    BLink,
    BImg,
    BButton,
    BBadge,
    BFormGroup,
    ChangeQuantity
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data() {
    return {
      host: process.env.VUE_APP_API_HOST
    }
  },
  computed: {
    ...mapGetters(['offerList', 'getLocale', 'brandLogo'])
  },
  methods: {
    productImage(product) {
      if (product.image_id) {
        return `https://${process.env.VUE_APP_API_HOST}/Image/GetImage?image_id=${product.image_id}`
      }
      return this.brandLogo
    },
    removeProductFromOfferClick(product) {
      this.$store.commit(REMOVE_OFFER, {
        product_id: product.product_id,
        unit_id: product.unit_id
      })
      this.$store.commit(SET_NOTIFICATION, {
        status: true,
        title: this.$t('offer.list'),
        message: this.$t('offer.remove_offer_message')
      })
    },
    getStockPieceByUnitID(product) {
      let rate = product?.rate ?? 1
      let rate2 = product?.rate2 ?? 1
      let productQuantity = product?.quantity ?? 1

      let amount = (rate / rate2) * productQuantity
      return `${amount} ${this.$t(`unit.${product.unit_id}`)}`
    }
  }
}
</script>

<style lang="scss">
.item-quantity-area {
  display: grid;
  gap: 0.5rem;

  p {
    padding-left: 2.7rem;
    padding-right: 6.2rem;
  }
}

.item-img {
  position: relative;

  .brand-image {
    position: absolute;
    top: 1rem;
    left: 2rem;

    img {
      width: 75px!important;
      height: 50px!important;
    }
  }
}
</style>
